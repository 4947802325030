import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { HidableBranding as Branding } from '../components/Branding';
import { Highlight } from '../components/Highlight';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SUBSCRIBER_COUNT } from '../constants';

const StyledA = styled('a')`
  border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        siteUrl="https://bloggingfordevs.com"
        description="Contact the creator of Blogging for Devs"
        pathname="contact/"
      />
      <ProductHuntBanner />
      <div
        style={{
          padding: '16px',
          margin: '0 auto'
        }}
      >
        <Wrapper style={{ maxWidth: '650px', textAlign: 'center' }}>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ marginBottom: '24px' }}
          >
            Contact
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Thanks for your interest in contacting Blogging for Devs!
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            My name's Monica, and I run the Blogging for Devs community,
            newsletter, and website.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            You can reach me most easily by email at{' '}
            <StyledA href="mailto:hi@bloggingfordevs.com">
              hi@bloggingfordevs.com
            </StyledA>
            .
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
